import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"

import s from './pick-a-donor-level.module.scss'

export function PickADonorLevel() {
    const containerAnimation = {
        show: {
            transition: {
                staggerChildren: 0.15
            }
        }
    };
    
    const linkAnimation = {
        hidden: { 
            opacity: 0,
            y: 25
        },
        show: { 
            opacity: 1,
            y: 0
        }
    };

    const easing = {
        type: "spring",
        mass: 0.35,
        damping: 2.5,
        stiffness: 15
    }

    return (
        <div className={s.wrapper}>
            <motion.ul className="levels" variants={containerAnimation} initial="hidden" animate="show">
                <motion.li className={s.btn} key={1} variants={linkAnimation} transition={easing}>
                    <Link to="/level/1">
                        <span className={s.btnTitle}>Level 1</span>
                        <span className={s.btnPrice}>$10,000 - $24,999</span>
                    </Link>
                </motion.li>
                <motion.li className={s.btn} key={2} variants={linkAnimation} transition={easing}>
                    <Link to="/level/2">
                        <span className={s.btnTitle}>Level 2</span>
                        <span className={s.btnPrice}>$5,000 - $9,999</span>
                    </Link>
                </motion.li>
                <motion.li className={s.btn} key={3} variants={linkAnimation} transition={easing}>
                    <Link to="/level/3">
                        <span className={s.btnTitle}>Level 3</span>
                        <span className={s.btnPrice}>$2,500 - $4,999</span>
                    </Link>
                </motion.li>
            </motion.ul>
        </div>
    )
}