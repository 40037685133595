import { motion } from 'framer-motion';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { DonorProvider } from './hooks/useDonorState';

import { Header } from './components/header/Header';
import { Levels } from './components/levels/Levels';
import { PickADonorLevel } from './components/pick-a-donor-level/PickADonorLevel';

import './App.scss';

function App() {
  return (
    <div className="App">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="wrapper">
          <DonorProvider>
            <BrowserRouter>

              <Header />

              <Switch>
                <Route exact path="/">
                  <PickADonorLevel />
                </Route>
                <Route path="/level/:number">
                  <Levels />
                </Route>
              </Switch>

            </BrowserRouter>
          </DonorProvider>
        </div>
      </motion.div>
    </div>
  );
}

export default App;