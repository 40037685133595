import React, { useState, useCallback } from 'react'
import { motion } from 'framer-motion';
import { Prompt, Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useDropzone } from 'react-dropzone'

import { ReactComponent as AlertSVG } from './../../../images/alert.svg';
import { ReactComponent as CloseSVG } from './../../../images/close.svg';
import { ReactComponent as ImageFolderSVG } from './../../../images/image-folder.svg';
import { EASING, INPUT_ANIMATION } from './../../../globals';
import { EditWrapper } from '../../edit-wrapper/EditWrapper'

ReactModal.setAppElement('#root');

export function Edit({content, currentLevel, handleContentUpdate}){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ignorePrompt, setIgnorePrompt] = useState(false);
    const onDrop = useCallback(acceptedFiles => {
        if(acceptedFiles.length){
            var reader = new FileReader();

            reader.onload = function (e) {
                handleContentUpdate('logo', e.target.result)
            };

            reader.readAsDataURL(acceptedFiles[0]);
        }
    }, [handleContentUpdate])
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    let { 
        logo, 
        name, 
        inMemoryMessage, 
        message 
    } = content

    return (
        <EditWrapper
            level={currentLevel}
            disableButton={name ? true : false}
        >
            <Prompt
                when={message === '' && !ignorePrompt}
                message={(location) => {
                    if(location.pathname.includes('preview') && message === ''){
                        setIsModalOpen(true)
                        return false;
                    } else {
                        return true;
                    }
                }}
            />
            <ReactModal 
                isOpen={isModalOpen} 
                className="modal-content"
                overlayClassName="modal-overlay"
                onRequestClose={() => setIsModalOpen(false)}
            >
                <button className="close-button" onClick={() => setIsModalOpen(false)}><CloseSVG/> Close</button>
                <div className="icon">
                    <AlertSVG />
                </div>
                <p>
                    <strong>
                        Are you sure you don’t want to add a message?
                    </strong>
                </p>
                <div className="button-group">
                    <button className="btn reverse" onClick={() => setIsModalOpen(false)}>Add message</button>
                    <Link 
                        className="btn"
                        to={()=>{
                            setIgnorePrompt(true);
                            return `/level/${currentLevel}/preview`;
                        }}
                    >
                        Yes, I'm sure
                    </Link>
                </div>
            </ReactModal>
                <motion.div
                    variants={INPUT_ANIMATION}
                    transition={EASING}
                >
                    <div className={`file-wrapper ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        {(logo && !isDragActive) 
                            ?   <img src={logo} alt="Logo" />
                            :   <>
                                    <ImageFolderSVG />
                                    <p>Drag and drop a custom logo<br/><span class="small-text">Use .png or .jpg for best results. If no custom logo is provided, NCF logo will be used</span></p>
                                </>
                        }
                    </div>
                </motion.div>
                <motion.div
                    variants={INPUT_ANIMATION}
                    transition={EASING}
                >
                    <textarea 
                        rows="1"
                        placeholder="Donor Name" 
                        value={name} 
                        onChange={(e) => {
                            handleContentUpdate('name', e.target.value)
                        }}
                    />
                </motion.div>
                <motion.div
                    variants={INPUT_ANIMATION}
                    transition={EASING}
                >
                    <textarea
                        rows="1"
                        placeholder="“In memory of” message (optional)" 
                        value={inMemoryMessage}
                        onChange={(e) => {
                            handleContentUpdate('inMemoryMessage', e.target.value)
                        }}
                    />
                </motion.div>
                <motion.div
                    variants={INPUT_ANIMATION}
                    transition={EASING}
                >
                    <textarea 
                        rows="4" 
                        placeholder="Message (optional)" 
                        value={message}
                        onChange={(e) => {
                            handleContentUpdate('message', e.target.value)
                        }}
                    />
                </motion.div>
        </EditWrapper>
    )
}