import React, { useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as ArrowDown } from './../../images/arrow-down.svg';
import { ReactComponent as Loader } from './../../images/loader.svg';
import { ReactComponent as Checkmark } from './../../images/check-circ.svg';

import { ExportToPDF } from './../../hooks/exportToPDF';

import s from './preview-wrapper.module.scss'

export function PreviewWrapper({level, children}) {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const previewRef = useRef(null);

    const handleDownload = (previewHTML) => {
        if(isDownloading){ return false; }

        let year = new Date().getFullYear();
        let filename = `NCF_${year}_Donorwall_Level${level}.pdf`;
        setIsDownloading(true);
        
        if(previewHTML){
            var pdf = ExportToPDF(filename, previewHTML).then(() => {
                setIsDownloading(false);
                setIsSuccess(true)
                // Unhide scrollbar after we are done exporting
                document.body.style.overflow = '';
            });
            return pdf;
        }
    }

    useEffect(() => {
        // Hide success button after two seconds
        if(isSuccess){
            setTimeout(() => {
                setIsSuccess(!isSuccess);
            }, 2000)
        }
    }, [isSuccess])

    const previewEasing = {
        type: "spring",
        mass: 0.35,
        damping: 2.5,
        stiffness: 15
    }

    return (
        <>
            <AnimatePresence>
                <motion.div
                    className={s.previewWrapper}
                    transition={previewEasing}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 1, y: 15 }}
                >
                    <div 
                        ref={previewRef} 
                        className={s.previewContent}
                    >
                        {children}
                        <span className={s.previewBorder}></span>
                    </div>
                    <div className={s.previewShadow}></div>
                </motion.div>
            </AnimatePresence>
            <div className={s.btnWrapper}>
                <button
                    className={`btn ${isDownloading ? 'is-loading' : ''} ${isSuccess ? 'is-success' : ''}`}
                    onClick={() => {
                        if(previewRef){
                            handleDownload(previewRef.current)
                        }
                    }}
                >
                        { isDownloading 
                            ?   <>Downloading <Loader /></>
                            :   isSuccess 
                                ?   <>Success <Checkmark /></>
                                :   <>Download <ArrowDown /></>
                        }
                </button>
            </div>
        </>
    )
}