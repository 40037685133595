import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { Prompt, Link } from 'react-router-dom';
import ReactModal from 'react-modal';

import { ReactComponent as AlertSVG } from './../../../images/alert.svg';
import { ReactComponent as CloseSVG } from './../../../images/close.svg';
import { EASING, INPUT_ANIMATION } from './../../../globals';
import { EditWrapper } from '../../edit-wrapper/EditWrapper'

import s from './level3.module.scss'

ReactModal.setAppElement('#root');

export function Edit({content, currentLevel, handleContentUpdate}){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ignorePrompt, setIgnorePrompt] = useState(false);
    let { 
        names, 
    } = content

    let allDonorNamesFilled = names.every(function(name) {
        return name !== '';
    });

    const inputs = names.map((name, i) => {
        return (
            <motion.div 
                key={i} 
                className={s.inputGroup}
                variants={INPUT_ANIMATION}
                transition={EASING}
            >
                <textarea 
                    rows="1"
                    placeholder={`Donor Name #${i + 1}`}
                    value={name} 
                    onChange={(e) => {
                        handleContentUpdate(i, e.target.value)
                    }}
                />
            </motion.div>
        )
    });

    return (
        <EditWrapper
            level={currentLevel}
            disableButton={names[0] ? true : false}
        >
            <Prompt
                when={!allDonorNamesFilled && !ignorePrompt}
                message={(location) => {
                    if(location.pathname.includes('preview')){
                        setIsModalOpen(true)
                        return false;
                    } else {
                        return true;
                    }
                }}
            />
            <ReactModal 
                isOpen={isModalOpen} 
                className="modal-content"
                overlayClassName="modal-overlay"
                onRequestClose={() => setIsModalOpen(false)}
            >
                <button className="close-button" onClick={() => setIsModalOpen(false)}><CloseSVG/> Close</button>
                <div className="icon">
                    <AlertSVG />
                </div>
                <p>
                    <strong>Are you sure you don't want to include <span class="primary-hl1">6</span> donors? This will reduce the total number of donors listed on the donor wall.</strong>
                </p>
                <div className="button-group">
                    <button className="btn reverse" onClick={() => setIsModalOpen(false)}>Add more</button>
                    <Link 
                        className="btn"
                        to={()=>{
                            setIgnorePrompt(true);
                            return `/level/${currentLevel}/preview`;
                        }}
                    >
                        Yes, I'm sure
                    </Link>
                </div>
            </ReactModal>
            <div className={s.inputGrid}>
                {inputs}
            </div>
        </EditWrapper>
    )
}