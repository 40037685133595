import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

import { GetCurrentLevel } from './../../hooks/getCurrentLevel';
import { IsPreview } from './../../hooks/isPreview';

import s from './progress-bar.module.scss';

export function ProgressBar() {
    let currentLevel = GetCurrentLevel();
    let isPreview = IsPreview();
    const stepOne = useAnimation();
    const stepTwo = useAnimation();

    useEffect(() => {
        const lineEasing = {
            ease: 'linear',
            duration: '0.2'
        }
        const inLineAnim = {
            x: '100%',
            transition: lineEasing
        }
        const outLineAnim = {
            x: '-100%',
            transition: lineEasing
        }

        // Animate lines in
        if(currentLevel){
            stepOne.start(inLineAnim);
        }
        if(isPreview){
            stepTwo.start(inLineAnim);
        }

        // Animate lines back out
        return () => {
            if(currentLevel){
                stepOne.start(outLineAnim); 
            }
            if(isPreview){
                stepTwo.start(outLineAnim); 
            }
        }
    }, [stepOne, stepTwo, currentLevel, isPreview])

    return (
        <>
            <div className={s.progressBar}>
                <span className={`${s.progressLineWrapper} ${s.stepOne}`}>
                    <motion.span 
                        className={s.progressLine}
                        animate={stepOne}
                    ></motion.span>
                </span>
                <ProgressDot 
                    isActive={!currentLevel && !isPreview}
                    isFilled={true} 
                />
                <span className={s.line}></span>
                <ProgressDot 
                    isActive={currentLevel && !isPreview}
                    isFilled={currentLevel} 
                />
                <span className={`${s.progressLineWrapper} ${s.stepTwo}`}>
                    <motion.span 
                        className={s.progressLine}
                        animate={stepTwo}
                    ></motion.span>
                </span>
                <span className={s.line}></span>
                <ProgressDot 
                    isActive={currentLevel && isPreview}
                    isFilled={isPreview} 
                />
            </div>
        </>
    )
}

function ProgressDot({ isActive, isFilled }){

    const transition = {
        ease: 'easeInOut',
        duration: 0.2
    }

    return (
        <>
            <span className={s.dot}>
                <AnimatePresence>
                    {isFilled &&
                        <motion.span 
                            className={`${s.innerDot} ${isActive ? s.isActive : ''}`}
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0}}
                            transition={transition}
                        >
                        </motion.span>
                    }
                </AnimatePresence>
            </span>
        </>
    )

}