import React from 'react'
import { Redirect } from 'react-router-dom';

import logoPNG from './../../../images/logo.png'
import { PreviewWrapper } from './../../preview-wrapper/PreviewWrapper'

import s from './level1.module.scss'

export function Preview({ content, currentLevel }){
    let { 
        logo,
        name, 
        inMemoryMessage, 
        message 
    } = content

    if(name === ''){
        return (
            <>
                <Redirect to={`/level/${currentLevel}`} />
            </>
        )
    } else {
        return (
            <PreviewWrapper level={currentLevel}>
                <div className={s.main}>
                    <img className={logo ? s.customLogo : ''} src={logo ? logo : logoPNG} alt=""/>
                    <h1 className={s.name}>
                        {name}
                    </h1>
                    {inMemoryMessage &&
                        <div className={s.inMemoryMessage}>
                            {inMemoryMessage}
                        </div>
                    }
                </div>
                {message &&
                    <p className={s.message}>
                        {message}
                    </p>
                }
            </PreviewWrapper>
        )
    }
}