import React from 'react'
import { ReactComponent as LogoSVG } from './../../images/NCF_2020_Logo.svg';

import s from './logo.module.scss';

export function Logo({ width }) {
    return (
        <LogoSVG 
            className={s.logo} 
            style={{"width": width ? width : '9rem'}} 
        />
    )
}