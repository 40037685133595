import React from 'react'
import { GetCurrentLevel } from './../../hooks/getCurrentLevel';

import s from './current-level.module.scss'

export function CurrentLevel() {
    let currentLevel = GetCurrentLevel();
    let price = false;

    if(currentLevel === '1'){
        price = "$10,000 - $24,999";
    } else if(currentLevel === '2'){
        price = "$5,000 - $9,999";
    } else if(currentLevel === '3'){
        price = "$2,500 - $4,999";
    }

    return (
        <span className={s.currentLevel}>
            Level {currentLevel} {price && `: ${price}`}
        </span>
    )
}