export const EASING = {
    type: "spring",
    mass: 0.35,
    damping: 2.5,
    stiffness: 15
};


export const INPUT_ANIMATION = {
    hidden: { 
        opacity: 0,
        y: 15
    },
    show: { 
        opacity: 1,
        y: 0
    }
};