import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom';

import { GetCurrentLevel } from '../../../hooks/getCurrentLevel'

import { Edit } from './Edit';
import { Preview } from './Preview';

export function Level1() {
    let currentLevel = GetCurrentLevel();

    let [ content, setContent ] = useState({
        logo: null, 
        name: '',
        inMemoryMessage: '',
        message: '',
    })

    const handleContentUpdate = (field, value) => {
        let updatedContent = { ...content };
        updatedContent[field] = value;
        setContent({ ...updatedContent })
    }
    
    return (
        <Switch>
            <Route exact path={`/level/${currentLevel}`}>
                <Edit 
                    content={content} 
                    currentLevel={currentLevel}
                    handleContentUpdate={handleContentUpdate} 
                />
            </Route>
            <Route exact path={`/level/${currentLevel}/preview`}>
                <Preview 
                    content={content}
                    currentLevel={currentLevel}
                />
            </Route>
        </Switch>
    )
}
