import html2pdf from 'html2pdf.js';

export function ExportToPDF(filename, HTMLcontent){
    var htmlRect = HTMLcontent.getBoundingClientRect();

    // Hide scrollbar for export
    document.body.style.overflow = 'hidden';

    // Not sure what this number comes from but it works
    var magicNumber = 2.835;

    var opt = {
        filename: filename,
        margin: [0, 0, 0, 0],
        image: { 
            type: 'jpeg', quality: 100
        },
        html2canvas: { 
            scale: 4,
            width: htmlRect.width * magicNumber,
            height: htmlRect.height * magicNumber,
            scrollX: 0,
            scrollY: 0,
        },
        jsPDF: { 
            orientation: 'l',
            format: [360, 360], 
            unit: 'mm', 
        },
    };

    return html2pdf().set(opt).from(HTMLcontent).save();
}

