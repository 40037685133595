import {useRouteMatch} from 'react-router-dom';

export function IsPreview(){
    let match = useRouteMatch("/level/:number/preview");
    let isPreview = false;

    if(match){
        isPreview = true;
    }

    return isPreview;
}