import React from 'react'
import { useRouteMatch, Redirect } from 'react-router-dom'

import { Level1 } from './level-1/Level1'
import { Level2 } from './level-2/Level2'
import { Level3 } from './level-3/Level3'

export function Levels({level}) {
    let match = useRouteMatch("/level/:number");
    let currentLevel = null;

    if(match && match.params){
        currentLevel = match.params.number;
    }

    switch(currentLevel){
        case '1':
            return (
                <Level1 />
            )

        case '2':
            return (
                <Level2 />
            )

        case '3':
            return (
                <Level3 />
            )

        default: {
            console.error(`Unhandled level: ${currentLevel}`);
            return (
                <Redirect to="/" />
            )
        }
    }
}