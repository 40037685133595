import React from 'react'
import { Redirect } from 'react-router-dom';

import { PreviewWrapper } from './../../preview-wrapper/PreviewWrapper'

import s from './level2.module.scss'

export function Preview({ content, currentLevel }){
    let { donors } = content;

    if(donors[0].name === ''){

        return <Redirect to={`/level/${currentLevel}`} />

    } else {

        let donorsPreview = donors.map((donor, i) => {
            if(donor.name !== ''){
                return (
                    <li key={i} className={s.donor}>
                        {donor.name &&
                            <h1 className={s.name}>
                                {donor.name}
                            </h1>
                        }
                        {donor.message &&
                            <p className={s.message}>
                                {donor.message}
                            </p>
                        }
                    </li>
                )
            } else {
                return false;
            }
        });

        return (
            <PreviewWrapper level={currentLevel}>
                <ul className={s.donors}>
                    {donorsPreview}
                </ul>
            </PreviewWrapper>
        )

    }
}