import React from 'react'
import { Link } from 'react-router-dom';
import {ReactComponent as ArrowLeft} from './../../images/arrow-left.svg';

import { GetCurrentLevel } from './../../hooks/getCurrentLevel';
import { IsPreview } from './../../hooks/isPreview';

import s from './back-link.module.scss';

export function BackLink() {
    let isPreview = IsPreview();
    let currentLevel = GetCurrentLevel();

    let backLink = '/';

    if(isPreview){
        backLink = `/level/${currentLevel}`;
    }

    return (
        <Link to={backLink} className={s.backLink}>
            <ArrowLeft className={s.arrow} />
            <span className={s.text}>Go Back</span>
        </Link>
    )
}
