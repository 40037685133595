import { motion } from 'framer-motion'

import { ProgressBar } from './../progress-bar/ProgressBar'
import { BackLink } from './../back-link/BackLink'
import { CurrentLevel } from './../current-level/CurrentLevel'
import { Logo } from './../logo/Logo'

import { GetCurrentLevel } from './../../hooks/getCurrentLevel'
import { IsPreview } from './../../hooks/isPreview'

import s from './header.module.scss';

export function Header() {
    let currentLevel = GetCurrentLevel();
    let isPreview = IsPreview();
    let heading = 'Pick a donor level';

    if(currentLevel === '1'){
        heading = 'Enter donor heading & message';
    } else if (currentLevel === '2'){
        heading = 'Enter donor(s) heading & message';
    } else if (currentLevel === '3'){
        heading = 'Enter donor(s) heading';
    }

    const containerAnimation = {
        show: {
            transition: {
                staggerChildren: 1
            }
        }
    };

    return (
        <>
            <header className={s.header}>
                <div>
                    { currentLevel && 
                        <motion.div
                            initial={{ opacity: 0, y: 5 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 5 }}
                        >
                            <BackLink/>
                        </motion.div>
                    }
                </div>
                { window.innerWidth > 768 &&
                    <div>
                        <ProgressBar />
                    </div>
                }
                <div>
                    { currentLevel && 
                        <motion.div
                            initial={{ opacity: 0, y: 5 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 5 }}
                            transition={{delay: 0.15}}
                        >
                            <CurrentLevel />
                        </motion.div>
                    }
                </div>
            </header>

            {!isPreview &&
                <div className={s.logo}>
                    <Logo />
                    <motion.h1 
                        className={s.title}
                        variants={containerAnimation} 
                        initial="hidden" 
                        animate="show"
                    >
                        {heading}
                    </motion.h1>
                </div>
            }
        </>
    )
}