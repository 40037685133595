import {useRouteMatch} from 'react-router-dom';

export function GetCurrentLevel(){
    let match = useRouteMatch("/level/:number");
    let currentLevel = null;

    if(match && match.params){
        currentLevel = match.params.number;
    }

    return currentLevel;
}
