import React from 'react'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { EASING } from './../../globals';
import { ReactComponent as Eye } from './../../images/eye.svg';

import s from './edit-wrapper.module.scss'

export function EditWrapper({ level, disableButton, children }) {

    const containerAnimation = {
        show: {
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const buttonAnimation = {
        hidden: { 
            opacity: 0,
        },
        show: { 
            opacity: 1,
        }
    };
    
    return (
        <div className={s.editWrapper}>
            <motion.div 
                className={s.editContent}
                variants={containerAnimation} 
                initial="hidden"
                animate="show"
            >
                {children}
            </motion.div>
            <motion.div 
                className={s.btnWrapper}
                variants={buttonAnimation}
                transition={EASING}
                initial="hidden"
                animate="show"
            >
                <Link 
                    className="btn"
                    to={`/level/${level}/preview`}
                    disabled={disableButton ? false : true}
                >
                    View Preview <Eye />
                </Link>
            </motion.div>
        </div>
    )
}