import React from 'react'

export const defaultState = {
    levels: [
        {
            price: '$10,000 - $24,999',
        },
        {
            price: '$5,000 - $9,999',
        },
        {
            price: '$2,500 - $4,999',
        }
    ],
    currentLevel: null,
}

const DonorStateContext = React.createContext()
const DonorDispatchContext = React.createContext()

function DonorReducer(state, action) {
    switch (action.type) {
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}
function DonorProvider({ children }) {
    const [state, dispatch] = React.useReducer(DonorReducer, defaultState)
    return (
        <DonorStateContext.Provider value={state}>
            <DonorDispatchContext.Provider value={dispatch}>
                {children}
            </DonorDispatchContext.Provider>
        </DonorStateContext.Provider>
    )
}
function useDonorState() {
    const context = React.useContext(DonorStateContext)
    if (context === undefined) {
        throw new Error('useDonorState must be used within a DonorProvider')
    }
    return context
}
function useDonorDispatch() {
    const context = React.useContext(DonorDispatchContext)
    if (context === undefined) {
        throw new Error('useDonorDispatch must be used within a DonorProvider')
    }
    return context
}

export { DonorProvider, useDonorState, useDonorDispatch }