import React from 'react'
import { Redirect } from 'react-router-dom';

import { PreviewWrapper } from './../../preview-wrapper/PreviewWrapper'

import s from './level3.module.scss'

export function Preview({ content, currentLevel }){
    let { names } = content

    if(names[0] === ''){

        return <Redirect to={`/level/${currentLevel}`} />

    } else {

        let namesPreview = names.map((name, i) => {
            if(name !== ''){
                return (
                    <li key={i} className={s.name}>
                        {name}
                    </li>
                )
            } else {
                return false;
            }
        });

        return (
            <PreviewWrapper level={currentLevel}>
                <ul className={s.names}>
                    {namesPreview}
                </ul>
            </PreviewWrapper>
        )
    }
}